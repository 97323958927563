import { Link } from 'gatsby';
import React from 'react';

const Firm = () => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Firm</h1>
      </div>

      <div id="img-wrap">
        <img src="/img/lemire-cabinet-large.jpg" alt="Firm" title="Firm" />
      </div>

      <div id="info">
        <div id="texte" className="cabinet">
          <p>
            Founded in <span className="accent">1976</span> by Mr. Claude
            Lemire, Lemire Lemire avocats GP provides its clients with services
            of the highest quality.
          </p>
          <p>
            With our expertise in <span className="accent2">Family Law</span> (
            <Link to="/en/expertise/separation-divorce">
              Separation - Divorce
            </Link>
            , <Link to="/en/expertise/child-custody">Child Custody</Link>),{' '}
            <Link to="/en/expertise/construction">
              <span className="accent2">Construction Law</span>
            </Link>
            ,{' '}
            <Link to="/en/expertise/liability">
              <span className="accent2">Liability Law</span>
            </Link>
            ,{' '}
            <Link to="/en/expertise/real-estate">
              <span className="accent2">Real Estate Law</span>
            </Link>{' '}
            and{' '}
            <Link to="/en/expertise/insurance">
              <span className="accent2">Insurance Law</span>
            </Link>
            , we provide legal advices and solutions that meet the expectations
            of our customers.
          </p>
          <p>
            In an environment of strong competition, we give great importance to
            the special relationship between our customers and ourselves. The
            growth and self-enrichment that our firm witnesses are attributed to
            the high quality of our professional services.
          </p>
        </div>
      </div>
    </>
  );
};

Firm.propTypes = {};

export default Firm;
