import React from 'react';

import { PAGES } from '../../utils/utils';
import Firm from '../../components/en/firm';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const FirmPage = () => (
  <Layout lang="en" page={PAGES.CABINET}>
    <SEO title="Firm" lang="en" />
    <Firm />
  </Layout>
);

export default FirmPage;
